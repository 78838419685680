<template>
  <div class="hall-header">
    <div class="header_container">
      <div class="container user-container">
        <b-img fluid :src="headerImg" alt="Image 1"></b-img>
        <div class="sea-navigation">
          <div class="row">
            <div class="col text-center">
              <router-link to="/hall/hall-index">首页</router-link>
            </div>
            <div class="col text-center">
              <router-link to="/hall/hall-create">创建殿堂</router-link>
            </div>
            <div class="col text-center">
              <router-link to="/hall/personal/hall-related">个人中心</router-link>
            </div>
          </div>
        </div>
        <div class="header-user"><router-link to="/login">登录</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
  import headerImg from '@/assets/images/header-logo.png'
  export default {
    name: 'hall-header',
    data() {
      return {
        headerImg: headerImg
      }
    }
  }
</script>

<style lang="scss" scoped>
.hall-header {
.header_container {
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
    .col {
      min-width: 128px;
      a {
        color: #ffffff;
        font-size: 18px;
        text-decoration: none;
      }
      a:hover {
        color: #0f3e82;
        font-weight: 500;
      }

      .tab-active {
        color: #0f3e82;
        font-weight: 500;
      }
    }
    .sea-navigation {
      min-height:60px;
      line-height: 60px;
    }
    .user-container {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(169deg, #79AAD9 0%, #407DB2 100%);
      align-items: center;
      .img-fluid {
        margin: 8px 0;
        width: 216px;
        height: 67px;
      }
      .header-user {
        a {
          font-size: 18px;
          font-weight: 500;
          color: #282828;
          -webkit-transition: all 200ms ease;
          -moz-transition: all 200ms ease;
          -ms-transition: all 200ms ease;
          -o-transition: all 200ms ease;
          transition: all 200ms ease;
        }
      }
    }
  }
}
</style>
