<template>
  <div>
    <hall-header></hall-header>
    <router-view></router-view>
  </div>
</template>

<script>
  import HallHeader from '@/components/hall-header'
  export default {
    name: 'hall-view',
    components: {
      HallHeader
    }
  }
</script>

<style>

</style>
